import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Stack, Typography } from "@mui/material";
// 122A4D
function App() {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        background: "#122A4D",
        color: "#fff",
        height: "100vh",
        width: "100wh",
        textAlign: "center",
      }}
    >
      <img
        src="logo.png"
        style={{ width: 170, marginBottom: -24 }}
        className="App-logo"
        alt="logo"
      />
      <Typography variant="h3">Lightspeed Studios</Typography>
      <Typography
        sx={{
          fontSize: 16,
          maxWidth: 600,
          lineHeight: 1.6,
          mt: 3,
        }}
      >
        Welcome to our software development shop, where innovation thrives, and
        excellence is our foundation.
      </Typography>

      <Typography
        sx={{
          fontSize: 16,
          maxWidth: 600,
          lineHeight: 1.6,
          mt: 3,
        }}
      >
        Our team of brilliant minds crafts exceptional apps and products that
        exceed client expectations. With a user-centric approach and unwavering
        commitment to quality, we lead the industry in delivering transformative
        solutions. Innovation knows no bounds here, and we take pride in
        creating the best apps and products for our valued clients.
      </Typography>
      <Typography variant="body1" sx={{ mt: 2 }}>
        Send inquiries to{" "}
        <a style={{ color: "white" }} href="mailto:admin@lightspeedstudios.xyz">
          admin@lightspeedstudios.xyz
        </a>
      </Typography>
    </Stack>
  );
}

export default App;
